import { useEffect, useState } from 'react'
import instance from './_instance'
import './App.css'

const shuffle = (arr) => [...arr].sort(() => 0.5 - Math.random())

function App() {
  const [status, setStatus] = useState('pending')
  const [teams, setTeams] = useState([])
  const [players, setPlayers] = useState([])
  const [teamIdToPush, setTeamIdToPush] = useState(1)

  useEffect(() => {
    ;(async () => {
      let response = await instance.get('/players')
      setPlayers(response.data)

      response = await instance.get('/teams')
      setTeams(response.data)

      setStatus('resolved')
    })()
  }, [])

  const onPlayerToTeam = async () => {
    let draftedPlayers = teams[0].players.concat(teams[1].players, teams[2].players)
    let playersToDraft = shuffle(players.high).concat(shuffle(players.medium), shuffle(players.low))

    for (let i = 0; i < playersToDraft.length; i++) {
      if (!draftedPlayers.some((draftedPlayer) => draftedPlayer === playersToDraft[i])) {
        let response = await instance.post('/team/player', { player: playersToDraft[i], teamId: teamIdToPush })
        setTeams(response.data)
        setTeamIdToPush((prevTeamId) => {
          if (prevTeamId < 3) {
            return prevTeamId + 1
          } else {
            return 1
          }
        })
        break
      }
    }
  }

  const onResetTeams = async () => {
    const response = await instance.delete('/teams')
    setTeams(response.data)
    setTeamIdToPush(1)
  }

  return (
    <div className="App">
      <h1 className="title">Tarde Old School</h1>
      {status === 'resolved' && (
        <div className="teams">
          <div>
            <h2>Equipo A</h2>
            <ul>
              <li>Capitan: {teams[0].captain}</li>
              {teams[0].players.map((player) => (
                <li key={player}>{player}</li>
              ))}
            </ul>
          </div>
          <div>
            <h2>Equipo B</h2>
            <ul>
              <li>Capitan: {teams[1].captain}</li>
              {teams[1].players.map((player) => (
                <li key={player}>{player}</li>
              ))}
            </ul>
          </div>
          <div>
            <h2>Equipo C</h2>
            <ul>
              <li>Capitan: {teams[2].captain}</li>
              {teams[2].players.map((player) => (
                <li key={player}>{player}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {status === 'pending' && <h3>Cargando...</h3>}
      <h1 className="participants-title">Participantes</h1>
      {status === 'resolved' && (
        <div className="participants">
          <div>
            <h2>Nivel alto</h2>
            <ul>
              {players.high.map((player) => (
                <li key={player}>{player}</li>
              ))}
            </ul>
          </div>
          <div>
            <h2>Nivel medio</h2>
            <ul>
              {players.medium.map((player) => (
                <li key={player}>{player}</li>
              ))}
            </ul>
          </div>
          <div>
            <h2>Nivel bajo</h2>
            <ul>
              {players.low.map((player) => (
                <li key={player}>{player}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {status === 'pending' && <h3>Cargando...</h3>}
      {status === 'resolved' && (
        <div className="buttons">
          <button className="button" onClick={onPlayerToTeam}>
            Sortear
          </button>
          <button className="button" onClick={onResetTeams}>
            Reiniciar equipos
          </button>
        </div>
      )}
    </div>
  )
}

export default App
